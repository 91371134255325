// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

const images = require.context('../images/frontend', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/frontend/frontend.scss"
import 'swiper/swiper-bundle.css';
import 'lightbox2/dist/css/lightbox.css';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
//import '@fortawesome/fontawesome-free/js/regular'
//import '@fortawesome/fontawesome-free/js/brands'

//import '../javascript/vendor/skrollr.min.js';


import Swiper from 'swiper/bundle';
const Isotope = require('isotope-layout');
const imagesLoaded = require('imagesloaded');
const lightbox = require('lightbox2');
import CookiesEuBanner from 'cookies-eu-banner'

document.addEventListener('DOMContentLoaded', () => {
  new CookiesEuBanner(() => {
    console.log('Cookies EU Banner accepted')
  })
})

//
// Swiper
//
const swiper = new Swiper('.swiper-container', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  }
});

//
// Image gallery
//
const gItems = document.querySelectorAll('.gallery-items');
if (gItems.length > 0) {

  imagesLoaded( '.gallery-items', function() {
    var iso = new Isotope( '.gallery-items', {
      singleMode: true,
      columnWidth: ".grid-sizer, .grid-sizer-second, .grid-sizer-three",
      itemSelector: ".gallery-item, .gallery-item-second, .gallery-item-three"
    });
  });

}

//
// Lightbox gallery
//
lightbox.option({
  'resizeDuration': 200,
  'wrapAround': true
})
